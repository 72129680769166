.App {
  text-align: center;
}

.body {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.react-flow__node-group {
  background-color: transparent;
}

a {
  color: #4e96d2;
  font-size: "inherit";
}
