.quill-root .ql-toolbar .ql-formats button,
.quill-root .ql-toolbar .ql-formats .ql-picker-label,
.quill-root .ql-toolbar .ql-formats .ql-picker-item {
  color: #e2e2e2;
}

.quill-root .ql-toolbar .ql-stroke {
  stroke: #e2e2e2;
}

.quill-root .ql-toolbar .ql-fill {
  fill: #e2e2e2;
}

.quill-root .ql-toolbar .ql-picker {
  color: #e2e2e2;
}

.quill-root .ql-toolbar .ql-picker-options {
  background-color: #3c3d47;
}

.quill-root .ql-toolbar .ql-picker-item:hover,
.quill-root .ql-toolbar .ql-picker-label:hover,
.quill-root .ql-toolbar .ql-picker-label.ql-active {
  color: #0066cc;
}

.quill-root .ql-toolbar .ql-active,
.ql-toolbar .ql-formats button.ql-active {
  color: #e2e2e2;
}

.quill-root .ql-toolbar .ql-picker-item.ql-selected {
  color: #0066cc;
}
